<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            ref="barrel"
            :expandRowByClick="false"
            @expandedRowsChange="handleExpandedRowsChange($event)"
            :expandedRowRender="true"
			:submit_preprocessing="submit_preprocessing"
            :slot_table_list="['operation','operations']"
            :rowKey="'id'"
        >
			<!-- <template slot="right_btn">
			    <a v-if="this.$store.state.page_auth.export" @click="report()">
					<a-button class="a_btn">导出</a-button>
				</a>
			</template> -->
            <template slot="operation" slot-scope="data">
			    <a @click="go_detail(data.record)">查看</a>
			</template>
            <template slot="expandedRowRender" slot-scope="data">
                <a-table
                    :columns="columns_sub"
                    :pagination="false"
                    :dataSource="data.record.list"
                    style="margin: 0"
                >
                    <!-- <template slot="footer" slot-scope>
                        <div
                            style="text-align:right"
                        >共{{data.record.goods.goods_num}}件商品，总计：¥{{data.record.goods.goods_total}}</div>
                    </template> -->
                    <template
                        slot="emptyBarrelInfo"
                        slot-scope="text,record"
                    >{{record.emptyBarrelInfo ? '桶装' : '瓶装'}}</template>
                    
                    <template slot="operations" slot-scope="record">
                        <a @click="go_detail(record)">查看</a>
                    </template>
                </a-table>
            </template>
        </TableList>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import { get_empty_barrel, get_seller_empty } from "@/api/push";
import TableList from "@/components/TableList";
const columns = [
    {
        title: "仓库",
        dataIndex: "storage_name",
    },
    {
        title: "司机",
        dataIndex: "chauffeur",
    },
    {
        title: "司机电话",
        dataIndex: "chauffeur_mobile",
    },
    {
        title: "车牌",
        dataIndex: "truck_license",
    },
    {
        title: "仓管",
        dataIndex: "username",
    },
    {
        title: "状态",
        dataIndex: "status_name",
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

const columns_sub = [
    {
        title: "经销商",
        dataIndex: "seller_name",
    },
    {
        title: "经销商编号",
        dataIndex: "seller_id",
    },
    {
        title: "空桶数量",
        dataIndex: "num",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operations"
        }
    }
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
			submit_preprocessing: {
			    array_to_string: ['department']
			},
            get_table_list: get_empty_barrel,
            columns,
            columns_sub,
            visible: false,
            form_data_seo: {
                list: [           
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        placeholder: '仓管/仓库/司机/车牌',
                        options: {},
					},
					// {
					//     type: "tree-select",
					//     name: "department",
					//     title: "部门",
					//     options: {},
					//     treeData: [],
					//     multiple: true
					// },
                    // {
                    //     type: "text",
                    //     name: "customer",
                    //     title: "客户",
                    //     placeholder: '客户名称/ID/地址',
                    //     options: {
                    //     }
                    // },
					{
                        type: "select",
                        name: "status",
                        title: "状态",
                        mode: "default",
                        options: {},
                        list: [
                            {
                                key:0,
                                value:'待核查'
                            },
                            {
                                key:1,
                                value:'仓库录入中'
                            },
                            {
                                key:2,
                                value:'仓库核对完成'
                            },
                            {
                                key:3,
                                value:'司机核对完成'
                            },
                        ]
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                       options: {
                       	initialValue: [this.$moment(new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)), 'YYYY/MM/DD'), this.$moment(new Date(), 'YYYY/MM/DD')],
                       },
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
	created() {
		this.$method.get_department().then(res => {
		    this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
		});
	},
    mounted() {
        // window.addEventListener('popstate', this.handleBackSendButton,false);
      },
	  activated() {
	  	// this.$refs.barrel.get_list();
	  },
    methods: {
    	handleBackSendButton(){
    			this.$refs.barrel.get_list();
    	},
        async handleExpandedRowsChange(e) {

            e.forEach(item => {
                get_seller_empty({
                        data: {
                            id: item,
                        }
                    }).then(res => {
                        let list = [];
                        this.$refs.barrel.list.forEach((vo, index) => {
                            if (vo.id == item) {
                                vo.list = res.data.list
                            }
                            list.push(vo)
                        })
                        this.$refs.barrel.set_data('list', list)
                    })

                // if (!this.$refs.list.list[item].list) {
                //     get_seller_empty({
                //         data: {
                //             id: this.$refs.list.list[item].id,
                //         }
                //     }).then(res => {
                //         let list = [];
                //         this.$refs.list.list.forEach((vo, index) => {
                //             if (index == item) {
                //                 vo.list = res.data.list
                //             }
                //             list.push(vo)
                //         })
                //         this.$refs.list.set_data('list', list)
                //     })
                // }
            })
        },
		go_detail(e) {
            this.$keep_route.add(this.$options.name);
			this.$router.push({
				path:"/barrel/details",
				query:{
					id:e.id,
                    seller_id:e.seller_id?e.seller_id:''
				}
			})
		},
    }
};
</script>

<style lang="less">
@import url("../../../assets/less/app.less");
</style>